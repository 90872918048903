import classnames from 'classnames';

type Props = {
  readonly className?: string;
};

function ScanQRCodeBox({className}: Props) {
  return (
    <div className={classnames('bg-white flex items-center pl-3 pr-5 py-2 rounded-md', className)}>
      <svg className="me-3" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g stroke="#242430" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75">
          <path d="M9.52999 1.52006C7.01999 1.49006 4.49999 1.47006 1.98999 1.44006C1.91999 4.00006 1.84999 6.56006 1.78999 9.12006" />
          <path d="M1.51999 19.16C1.18999 21.74 1.03999 24.35 1.05999 26.95C1.05999 27.07 1.06999 27.2 1.14999 27.29C1.24999 27.4 1.40999 27.4 1.54999 27.4C3.24999 27.37 4.93999 27.33 6.63999 27.3" />
          <path d="M21.76 0.880005C24.08 1.07 26.41 1.25 28.73 1.44C28.86 1.45 29 1.47 29.1 1.56C29.21 1.66 29.22 1.82 29.22 1.97C29.3 3.87 29.38 5.91 29.46 7.81" />
          <path d="M19.6 28.0401C22.18 28.1501 25.16 28.1601 27.74 28.0701C27.93 28.0601 28.13 28.0501 28.29 27.9401C28.46 27.8101 28.76 23.6001 28.69 21.6901" />
          <path d="M23.22 7.66996C23.19 9.64996 23.17 11.64 23.14 13.62C21.49 13.58 19.84 13.54 18.19 13.5C18.04 13.5 17.87 13.48 17.78 13.36C17.71 13.27 17.71 13.14 17.71 13.03C17.75 11.55 17.81 10.07 17.9 8.58996C17.91 8.42996 17.93 8.24996 18.04 8.13996C18.15 8.02996 18.32 8.02996 18.47 8.01996C19.96 7.87996 21.57 7.44996 23.02 7.73996" />
          <path d="M12.27 7.69002C12.29 9.31002 12.3 10.92 12.32 12.54C12.32 12.67 12.32 12.81 12.23 12.9C12.14 13 11.99 13.02 11.85 13.03C10.21 13.15 8.20999 13.12 6.57999 12.93C6.58999 11.46 6.44999 9.64002 6.64999 8.18002C6.66999 8.02002 6.69999 7.85002 6.79999 7.73002C6.95999 7.54002 7.72999 7.52002 7.97999 7.52002C9.15999 7.52002 10.45 7.63002 11.63 7.63002" />
          <path d="M12.43 17.79C12.4 19.13 12.37 21.1 12.34 22.44C12.34 22.59 12.33 22.76 12.22 22.86C12.11 22.96 11.95 22.96 11.8 22.96C10.41 22.92 9.02999 22.88 7.63999 22.84C7.43999 22.83 6.85999 22.82 6.71999 22.67C6.57999 22.52 6.58999 22.28 6.59999 22.07C6.72999 20.55 6.84999 19.04 6.97999 17.52C8.55999 17.4 10.5 17.44 12.07 17.64" />
          <path d="M17.85 18.14C17.76 19.47 17.67 20.79 17.58 22.12C17.57 22.24 17.57 22.37 17.65 22.46C17.72 22.53 17.82 22.54 17.91 22.55C19.6 22.69 21.28 22.83 22.97 22.97C23.11 22.98 23.27 22.99 23.38 22.89C23.47 22.8 23.48 22.66 23.48 22.54C23.53 20.95 23.57 19.36 23.62 17.77C21.74 17.61 19.84 17.58 17.95 17.67" />
        </g>
      </svg>

      <div className="text-darkBlue"> Scan QR Code </div>
    </div>
  );
}

export default ScanQRCodeBox;
