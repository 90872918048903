import * as React from 'react';
import Sprite from './assets/small.png';

type Props = {
  sprite?: string;
  startFrame?: number;
  totalFrames?: number;
  framesPerRow?: number;
  width?: number;
  height?: number;
};

function TriangleSpinner({
  sprite = Sprite,
  startFrame = 0,
  totalFrames = 23,
  framesPerRow = 5,
  width = 42,
  height = 38,
}: Props) {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const rafRef = React.useRef<ReturnType<typeof requestAnimationFrame>>();
  const previousTimeRef = React.useRef(0);
  const frameRef = React.useRef(startFrame);
  const [image, setImage] = React.useState<HTMLImageElement | null>(null);

  const getSpritePosition = React.useCallback(
    (frameIndex: number) => {
      const row = Math.floor(frameIndex / framesPerRow);
      const col = frameIndex % framesPerRow;
      return [width * col, height * row];
    },
    [width, height, framesPerRow]
  );

  React.useEffect(() => {
    const {current: canvas} = canvasRef;

    if (!image) return;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (context == null) return;

    const loop = (time: number) => {
      const deltaTime = time - previousTimeRef.current;

      if (deltaTime > 100) {
        const nextFrame = frameRef.current + 1 >= totalFrames ? 0 : frameRef.current + 1;
        const [frameX, frameY] = getSpritePosition(nextFrame);

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, frameX, frameY, width, height, 0, 0, width, height);
        frameRef.current = nextFrame;
        previousTimeRef.current = time;
      }

      rafRef.current = requestAnimationFrame(loop);
    };

    rafRef.current = requestAnimationFrame(loop);
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [image, getSpritePosition, canvasRef, height, width, totalFrames]);

  React.useEffect(() => {
    const image = new Image();
    image.src = sprite;
    image.onload = () => setImage(image);
  }, [sprite]);

  return <canvas ref={canvasRef} width={width} height={height} />;
}

export default React.memo(TriangleSpinner);
