import ScanQRCodeBox from './ScanQRCodeBox';
import AppIcon from './AppIcon';
import AppTabs from './AppTabs';

type Props = {
  className?: string;
};

function ScanInstructions({className}: Props) {
  return (
    <div className={className}>
      <ol className="list-decimal list-inside text-lg">
        <li>
          <div className="inline-flex items-center">
            Open <AppIcon className="mx-4" /> on your phone
          </div>
        </li>
        <li className="mt-4">
          <div className="inline-flex items-center flex-wrap -mt-2">
            <div className="mt-2"> Scroll across to the Up tab </div>
            <AppTabs className="ms-4 mt-2" />
          </div>
        </li>
        <li className="mt-4">
          <div className="inline-flex items-center flex-wrap -mt-2">
            <div className="mt-2"> Select </div>
            <ScanQRCodeBox className="mx-4 mt-2" /> <div className="mt-2">then follow the instructions.</div>
          </div>
        </li>
      </ol>

      <div className="md:mt-16 mt-4">
        <div className="text-lg text-altWhite">Viewing an existing token</div>
        <div className="text-altWhite opacity-50 max-w-md mt-4">
          For security purposes you cannot view an existing token. we advise keeping a copy of your token somewhere
          secure.
        </div>
      </div>

      <div className="md:mt-16 mt-4">
        <div className="text-lg text-altWhite">Generating an existing token</div>
        <div className="text-altWhite opacity-50 max-w-md mt-4">
          You can easily generate a new token to replace your existing one via the Up app. Swipe right to the Up tab and
          select ‘Data sharing’ from the settings. Tap Personal Access Token and follow the prompts.
        </div>
      </div>

      <div className="md:mt-8 mt-4">
        <div className="text-lg text-altWhite">Revoking an existing token</div>
        <div className="text-altWhite opacity-50 max-w-md mt-4">
          You can easily revoke an existing token via the Up app. Swipe right to the Up tab and select ‘Data sharing’
          from the settings. Tap Personal Access Token and select ‘Revoke existing token’.
        </div>
      </div>
    </div>
  );
}

export default ScanInstructions;
